.bg-gris {
  --background: #fafbfd;
}
.shadow-input {
  box-shadow: 0 5px 9px 0 rgba(54, 52, 52, 0.04);
}
.padding-home {
  --padding-start: 18px;
  --padding-end: 18px;
  --padding-top: 24px;
  --padding-bottom: 150px;
}
.boton-whapp {
  background: linear-gradient(90deg, #06ec57 0%, #38e776 100%);
}
.boton-whapp-inactive {
  background: #42e87d80;
}
.text-whapp {
  margin-top: -10px;
  display: inline-flex;
}
