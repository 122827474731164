.modal-custom .modal-wrapper {
  background-color: transparent;
}

.midle-circle {
  width: 100%;
  height: 170px;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffedc6;
  -moz-border-radius: 40px 40px 100% 100%;
  -webkit-border-radius: 40px 40px 100% 100%;
  border-radius: 40px 40px 100% 100%;
}

select {
  background-color: white;
}

.input-data {
  position: relative;
}

.input-data input {
  height: 100%;
  width: 100%;
  border: 0px solid transparent;
  font-size: 15px;
  padding-left: 5px;
  color: gray;
}
.input-data input:valid ~ label,
.input-data input:focus ~ label {
  transform: translateY(-15px);
  transition: 0.5s;
  margin-left: 5px;
  color: #fccb46;
  font-size: 10px;
}
.input-data input:focus {
  border: 0px solid transparent;
}
.input-data input::placeholder {
  border: 0px solid transparent;
}

.input-data label {
  margin-left: 5px;

  position: absolute;
  bottom: 10px;
  left: 0;
  color: grey;
  pointer-events: none;
}

input[type="checkbox"] {
  appearance: none;
  border-radius: 5px;
  height: 22px;
  width: 22px;
  margin: 0 20px 0 0 !important;
  font-size: 20px;
  border: 2px solid gray;
}

input[type="checkbox"]:after {
font-family: "Font Awesome 5 Free"; 
content: "\f00c";
font-weight: 600;
font-size: 18px;
color:#fccb46 ;
display: none ;
}
input[type="checkbox"]:hover {
background-color:   rgb(228, 213, 213);
border: 2px solid #fccb46;

}
input[type="checkbox"]:checked {
  background-color: rgb(228, 213, 213);
  border: 2px solid #fccb46;

  }
  input[type="checkbox"]:checked:after {
display: block;    }
input[type="radio"],
input[type="checkbox"] {
  margin: 0;
}
