.swiper-pagination-bullet-active {
  background: #ecc94b;
}


.carousel .control-dots {
  bottom:8px  !important;
}
.carousel .control-dots .dot{
  background:#ecc94b !important;
}