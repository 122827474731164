.bg-auth {
  --background: #fff url(../../img/bg-auth.png) no-repeat center top / cover;
}
.section-padding {
  --padding-start: 32px;
  --padding-end: 32px;
  --padding-top: 24px;
  --padding-bottom: 32px;
}
.border-gray-500-force {
  border: 1px solid #efefef !important;
  border-radius: 8px;
}
