.bg-creatingService {
  --background: #fff url(../../img/backgrounds/Fondo_Loading@1\,5x.png) no-repeat center top / cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section-padding {
  --padding-start: 32px;
  --padding-end: 32px;
  --padding-top: 24px;
  --padding-bottom: 32px;
}
.border-gray-500-force {
  border: 1px solid #efefef !important;
  border-radius: 8px;
}
.cancel-service-link{
  position: absolute;
  bottom: 50px;
  left: 38%;
}
.content-spining{
  position: absolute;
  top: 30%;
  margin: 0 30px;
}

.forward-modal{
  z-index: 1 !important;
  transition: 0.1s !important;
}


