.animation-up {
	animation: up 1.3s ease-in-out 0s;
}

.modal__close {
	top: -0.7em;
	right: -0.7em;
}

.modal__container {
	max-width: 20rem;
}

ion-content {
	--ion-background-color: transparent;
}

.border-item {
	border: 3px solid;
	background: white;
}

.border-item-gray {
	border: 3px solid #ededed;
	background: white;
}

.custom-col{
	display: flex;
	flex-direction: column;
	justify-content: center;
	
}
.top-50 {
	width: 100%;
	border-bottom: 3px solid #7de58d;
}

.top-50-gray {
	width: 100%;
	border-bottom: 3px solid #ededed;
}

.divider{
	border-bottom: 1px solid #e2e8f0;
	margin: 0 3%;
}
.divider-col{
	border-bottom: 1px solid #e2e8f0;
	margin: 2px 0;
	
}

.swiper-pagination-bullet{
	background:#f2be50;
}

.technical-icon{
	border-radius: 50%;
	height: 35px;
	width: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
}

.technical-icon-gray{
	border: 2px solid #dfdfdf;
	border-radius: 50%;
	height: 35px;
	width: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
}

.rounded-r-l{
    border-radius: 0px 10px 10px 10px;
    -moz-border-radius: 0px 10px 10px 10px;
    -webkit-border-radius: 0px 5px 5px 5px;
}

.rounded-r-r{
    border-radius: 10px 10px 0px 10px;
    -moz-border-radius: 10px 10px 0px 10px;
    -webkit-border-radius: 10px 10px 0px 10px;
}

.background-profile{
	width: 100%;
	height: 220px;
  display: flex;
  justify-content: center;
  background-image: url("../src/img/backgrounds/FondoPerfil.png");
  background-color: #f2f2f2;
  background-size: 100% 100%;
	position: relative;
}

.shadow-top{
	-webkit-box-shadow: -2px -5px 5px -4px rgba(133,133,133,0.66);
	-moz-box-shadow: -2px -5px 5px -4px rgba(133,133,133,0.66);
	box-shadow: -2px -5px 5px -4px rgba(133,133,133,0.66);
}

.deleteImageRounded{
	background: white;
	border-radius: 50%;
	height: 25px;
	width: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow:7px 2px 18px -7px rgba(0,0,0,0.82) ;
	left: 118px;
	position: absolute;
}

body.keyboard-is-open .hideElementOnKeyboardShown{
	display: none;        
}
.rccs__card--unknown > div{
	background: linear-gradient(25deg,#ededed,#f0f0f0) !important; 
	color: #9a9a9a !important;
	opacity: 1;
}
.rccs__card{
	width: 320px;
}

.rccs{
	width: 100% ;
}